import React, { useCallback, useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";

// region Imports - Material UI
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
// endregion Imports - Material UI
// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  DataTableMessages,
  GlobalMessages,
  MenuMessages,
  ScheduledReportMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import { TLanguages } from "@shared/languages/languageData";
import { FilterApplicationTypeMessages } from "@shared/languages/interfaces/filterMessages";
import { ReportStatusTypeMessages } from "@shared/languages/interfaces/scheduledReportMessages";
// endregion Imports - Languages
// region Imports - Shared
import { ScheduledReport } from "@shared/entities/reg_scheduled_reports.entity";
import { IParamQueryValues } from "@shared/interfaces/filter.interface";
import { ReportStatusType } from "@shared/types/report_status_type.enum";
// endregion Imports - Shared
// region Imports - Components
import ButtonTable from "@components/Button/ButtonTable";
import ReportFilterDetails from "@atoms/ReportFilterDetails";
import Header from "@components/Header";
import NewDataTable, { DataTableActions, DataTableColumns } from "@molecules/NewDataTable";
// endregion Imports - Components
// region Imports - Hooks
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Services
import api from "@services/api";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
// endregion Imports - Services
// region Imports - Styles
import { Container, ContainerModal } from "./styles";
// endregion Imports - Styles

const ScheduledReports: React.FC = () => {

  // region States
  const [scheduledReports, setScheduledReports] = useState<
    ScheduledReport[]>([] as Array<ScheduledReport>);

  const [filterOptionsDetails, setFilterOptionsDetails] = useState<IParamQueryValues[]>([]);
  const [filterDetailsTitle, setFilterDetailsTitle] = useState<string>("");
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  // endregion States
  // region Hooks
  const { addToast } = useToast();
  const { screen } = useSelector((screen) => screen);
  const { t, i18n } = useTranslation();
  // endregion Hooks
  // region Constants

  const dataTableSettings: DataTables.Settings = {
    columnDefs: [{ className: "dt-center", targets: -1 }],
    order: []
  };

  const dataTableColumns: DataTableColumns[] = [
    { // Description
      title: t(ScheduledReportMessages.columnDescription),
      data: (scheduledReport: ScheduledReport) => (scheduledReport?.filter?.description),
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.filter.description"
    },
    { // Report type
      title: t(ScheduledReportMessages.columnReportType),
      data: (scheduledReport: ScheduledReport) => (
        t(FilterApplicationTypeMessages[scheduledReport?.filter?.application_type?.id_filter_application_type as string])),
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.filter.application_type.id_filter_application_type"
    },
    { // Status
      title: t(ScheduledReportMessages.columnStatus),
      data: (scheduledReport: ScheduledReport) => {
        const text = t(ReportStatusTypeMessages[scheduledReport.status!]);
        const color = utils.getReportStatusTypeColor(scheduledReport.status!);

        return `<span style="color: ${color}; font-weight: bold;">${text}</span>`;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.status"
    },
    { // Registration date
      title: t(ScheduledReportMessages.columnRegistrationDate),
      data: (scheduledReport: ScheduledReport) => utils.formatDateIfHave(scheduledReport.registration_date!, "fullDate"),
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.registration_date"
    },
    { // Processing date
      title: t(ScheduledReportMessages.columnProcessingDate),
      data: (scheduledReport: ScheduledReport) => utils.formatDateIfHave(scheduledReport.processing_date!, "fullDate"),
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.registration_date"
    },
    { // File size
      title: t(ScheduledReportMessages.columnFileSize),
      data: (scheduledReport: ScheduledReport) => {
        if (!Number(scheduledReport.file_size)) {
          if (scheduledReport.status === ReportStatusType.PROCESSED) return t(ScheduledReportMessages.emptyReport);

          return "";
        }

        const fileSizeInMB = scheduledReport.file_size! / 1024 / 1024;

        return `${utils.formatNumberToLocaleString(fileSizeInMB, i18n.language as TLanguages, 2)} MB`;
      },
      defaultContent: "",
      filterable: true,
      propertyName: "scheduledReport.file_size"
    },
    { // Actions
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: (scheduledReport: ScheduledReport) => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable className="action-button modules-scheduledReport-list-details"><FormatListBulletedIcon /></ButtonTable>
          </Grid>
          { scheduledReport.file_url && scheduledReport.status === ReportStatusType.PROCESSED && (
            <Grid item xs sm md lg xl>
              <ButtonTable className="action-button modules-scheduledReport-list-download"><GetAppIcon /></ButtonTable>
            </Grid>
          )}
          { scheduledReport.status === ReportStatusType.IN_QUEUE && (
            <Grid item xs sm md lg xl>
              <ButtonTable className="action-button modules-scheduledReport-list-cancel"><CancelIcon /></ButtonTable>
            </Grid>
          )}
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];

  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-scheduledReport-list-details",
      callback: (rowData: ScheduledReport) => handleScheduledReportDetails(rowData)
    },
    {
      ref: ".modules-scheduledReport-list-download",
      callback: (rowData: ScheduledReport) => handleScheduledReportDownload(rowData)
    },
    {
      ref: ".modules-scheduledReport-list-cancel",
      callback: (rowData: ScheduledReport) => handleScheduledReportCancel(rowData)
    }
  ];
  // endregion Constants
  // region Functions
  /**
   * Search scheduled reports
   */
  const readScheduledReports = useCallback(async () => {

    setLoading(true);

    try {

      // Get the scheduled reports
      const { data } = await api.get("scheduled-reports/read-all");

      if (data.status === "success") {

        setScheduledReports([...data.result] as ScheduledReport[]);

      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        setScheduledReports([]);
        if (table.length) table.clear().draw();
      }

    } catch (error: any) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, table, t]);

  /**
   * Get the formatted filter options
   * @param idFilter The filter id to get the formatted options
   */
  const getFormattedFilterOptions = async (idFilter: string) : Promise<IParamQueryValues[]> => {
    const { data } = await api.get(`filters/get-formatted-filter-options/${idFilter}`);

    if (data.status === "success") return data.result as IParamQueryValues[];

    throw new Error(data.message);
  };

  /**
   * Renew the scheduled report URL
   * @param scheduledReport The scheduled report to renew the URL
   */
  const renewScheduledReportUrl = async (scheduledReport: ScheduledReport) : Promise<string> => {
    const { data } = await api.get(`scheduled-reports/renew-link/${scheduledReport.id_scheduled_report}`);

    if (data.status === "success") return data.result as string;

    throw new Error(data.message);
  };

  /**
   * Handle the scheduled report details
   */
  const handleScheduledReportDetails = async (scheduledReport: ScheduledReport) => {

    setLoading(true);

    try {
      const filterOptionsFormatted = await getFormattedFilterOptions(scheduledReport!.filter!.id_filter!);

      setFilterDetailsTitle(`${t(ScheduledReportMessages.filterDetailsTitle)}${scheduledReport.filter?.description}`);
      setFilterOptionsDetails(filterOptionsFormatted);
      setOpenDetailsModal(true);
    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle close the scheduled report details
   */
  const handleCloseScheduledReportDetails = () => setOpenDetailsModal(false);

  /**
   * Handle the scheduled report download
   * @param scheduledReport The scheduled report to download
   */
  const handleScheduledReportDownload = async (scheduledReport: ScheduledReport) => {
    setLoading(true);

    try {
      if (!scheduledReport?.url_expiration_date || (scheduledReport.url_expiration_date <= new Date())) {
        window.open(await renewScheduledReportUrl(scheduledReport), "_blank");

        return;
      }

      window.open(scheduledReport.file_url!, "_blank");
    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle the scheduled report cancel
   * @param scheduledReport The scheduled report to cancel
   */
  const handleScheduledReportCancel = async (scheduledReport: ScheduledReport) => {

    setLoading(true);

    try {
      const { data } = await api.patch(`scheduled-reports/cancel/${scheduledReport.id_scheduled_report}`);

      if (data.status === "success") {
        await readScheduledReports();

        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
      } else {
        addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
      }
    } catch (error: any) {
      utils.handleStandardError(error, addToast, t);
    } finally {
      setLoading(false);
    }
  };

  // endregion Functions
  // region Effects

  // Refresh the scheduled reports when the language is changed
  useEffect(() => {
    readScheduledReports().then();
  },
  // This effect is called once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [i18n.language]);
  // endregion Effects

  return (
    <>
      <Header title={t(MenuMessages.scheduledReports)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loading} />
        { !loading && (
          <NewDataTable
            title={t(MenuMessages.scheduledReports)}
            data={scheduledReports}
            columns={dataTableColumns}
            settings={dataTableSettings}
            actions={dataTableActions}
            returnTable={(table) => setTable(table)}
          />
        )}
        <ContainerModal id="modalScheduleReportDetails">
          <Dialog
            open={openDetailsModal}
            onClose={handleCloseScheduledReportDetails}
            scroll="paper"
            container={document.getElementById("modalScheduleReportDetails")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">{filterDetailsTitle}</div>
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <DialogContentText tabIndex={-1} component="div">
                <ReportFilterDetails filterOptions={filterOptionsDetails} />
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mFooter">
              <Button disableRipple onClick={handleCloseScheduledReportDetails} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModal>
      </Container>
    </>
  );
};

export default ScheduledReports;
